
.breadcrumbs {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 14px;
    height: 14px;
    background-color: #000;
    border: 2px solid #fff;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
    z-index:0;
  }

  .tooltip{
    display: none;
    z-index: 10000;
    margin-left: 20px;
    margin-top:-20px;
    background-color: green;
    width:100px;
    padding:5px;
    border-radius: 5px;
    border:1px solid white;
    color:white;

  }
  

  .current {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    background-color: "green";
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
    z-index: 100;
  }
  

.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #8a5e00f5;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}

.pin:hover + .tooltip {
  display: block;
}
.pin:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  -webkit-animation: flashing 1s infinite;  /* Safari 4+ */
  -moz-animation: fashing 1s infinite;  /* Fx 5+ */
  -o-animation: flashing 1s infinite;  /* Opera 12+ */
  animation: flashing 1s infinite;  /* IE 10+, Fx 29+ */
  position: absolute;
  border-radius: 50%;
}

@-webkit-keyframes flashing {
  0%, 49% {
    background-color: rgb(255, 255, 255);
  }
  50%, 100% {
    background-color: #ff7f30;
  }
}

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.pulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}
.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #00cae9;
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}